import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.setActiveLink();
  }

  setActiveLink() {
    const pathname = window.location.pathname;
    const activeLink = document.querySelector(`.sidebar-link[href="${pathname}"]`);
    const sidebarItems = document.querySelectorAll(
      'li.sidebar-item a.sidebar-link[data-bs-toggle="collapse"] + ul.sidebar-dropdown'
    );
  
    document.querySelectorAll(".sidebar-item.active").forEach((item) => {
      item.classList.remove("active");
    });
  
    document.querySelectorAll(".sidebar-dropdown.active").forEach((dropdown) => {
      dropdown.classList.remove("active");
    });
  
    if (pathname === "/") {
      return;
    }
  
    if (sidebarItems.length > 0) {
      for (let i = 0; i < sidebarItems.length; i++) {
        const sidebarItem = sidebarItems[i];
        const activeDropdownLink = sidebarItem.querySelector(
          `.sidebar-link[href="${pathname}"]`
        );
  
        if (activeDropdownLink) {
          const links = document.querySelectorAll(`.sidebar-link:not([href="${pathname}"])`);
          sidebarItem.parentElement.classList.add("active");
  
          links.forEach((link) => {
            const item = link.closest(".sidebar-item");
  
            if (item !== sidebarItem.parentElement) {
              const dropdown = item.querySelector(".sidebar-dropdown");
  
              item.classList.remove("active");
              if (dropdown) {
                dropdown.classList.remove("active");
              }
            }
          });
  
          break;
        }
      }
    }

    if (activeLink) {
      const activeItem = activeLink.closest(".sidebar-item");
      activeItem.classList.add("active");
  
      if (activeItem.classList.contains("unique")) {
        const links = document.querySelectorAll(`.sidebar-link:not([href="${pathname}"])`);
  
        links.forEach((link) => {
          const item = link.closest(".sidebar-item");
          const dropdown = item.querySelector(".sidebar-dropdown");
  
          item.classList.remove("active");
          if (dropdown) {
            dropdown.classList.remove("active");
          }
        });
      }
    }
  }
}