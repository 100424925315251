import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggle(event) {
    const currentCheckbox = event.target;
    const container = currentCheckbox.closest(".btn-group");

    this.uncheck(container, currentCheckbox);
  }

  uncheck(container, currentCheckbox) {
    container
      .querySelectorAll('input[type="checkbox"]')
      .forEach((checkbox) => {
        if (checkbox !== currentCheckbox) checkbox.checked = false;
      });
  }
}
