import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tonsFields', 'litersFields', 'calculatedTons'];

  connect() {
    const selectElement = this.element.querySelector('select');
    if (selectElement) {
      this.toggleFields({ target: selectElement });
    }
  }

  toggleFields(event) {
    const selectedProductId = event.target.value;
    const products = JSON.parse(this.element.dataset.products);
    const unitType = products[selectedProductId]?.unit_type;
  
    if (unitType === 'tons') {
      this.showTonsFields();
      this.litersFieldsTargets.forEach((field) => field.removeAttribute('required'));
    } else if (unitType === 'liters') {
      this.showLitersFields();
      this.litersFieldsTargets.forEach((field) => field.setAttribute('required', 'true'));
    }
  }  

  showTonsFields() {
    this.toggleFieldsVisibility(this.tonsFieldsTargets, true);
    this.toggleFieldsVisibility(this.litersFieldsTargets, false);
    this.enableTonsFields();
    this.disableLitersFields()
  }

  showLitersFields() {
    this.toggleFieldsVisibility(this.litersFieldsTargets, true);
    this.toggleFieldsVisibility(this.tonsFieldsTargets, false);
    this.enableLitersFields();
    this.disableTonsFields();
  }

  toggleFieldsVisibility(fields, visible) {
    fields.forEach((field) => {
      const parentTd = field.closest('td');
      if (visible) {
        parentTd.classList.remove('hidden');
      } else {
        parentTd.classList.add('hidden');
      }
    });
  }

  disableLitersFields() {
    this.litersFieldsTargets.forEach((field) => {
      field.setAttribute('disabled', 'true');
    });
  }

  enableLitersFields() {
    this.litersFieldsTargets.forEach((field) => {
      field.removeAttribute('disabled');
    });
  }


  disableTonsFields() {
    this.tonsFieldsTargets.forEach((field) => {
      field.setAttribute('disabled', 'true');
    });
  }

  enableTonsFields() {
    this.tonsFieldsTargets.forEach((field) => {
      field.removeAttribute('disabled');
    });
  }

  updateTons(event) {
    const conversionFactor = this.getConversionFactor();
    const litersField = event.target;
    const index = this.litersFieldsTargets.indexOf(litersField);

    if (index === -1) return;

    const calculatedTonsField = this.calculatedTonsTargets[index];
    if (calculatedTonsField) {
      const litersValue = parseFloat(litersField.value) || 0;
      const tonsValue = litersValue * conversionFactor;
      calculatedTonsField.value = tonsValue.toFixed(2);
    }
  }

  getConversionFactor() {
    const selectedProductId = this.element.querySelector('select').value;
    const products = JSON.parse(this.element.dataset.products);
    return products[selectedProductId]?.conversion_factor || 0;
  }
}
