import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  connect() {
    this.updateAllHeaderCheckboxes();
    document.addEventListener('turbo:load', this.updateAllHeaderCheckboxes.bind(this));
  }

  async toggle(event) {
    const { target: checkbox } = event;
    const { field, id } = checkbox.dataset;
    const checked = checkbox.checked;

    try {
      const response = await this.patchRequest({ ids: [id], field, value: checked });

      if (!response.ok) this.revertCheckboxState(checkbox, !checked);
      else this.updateHeaderCheckbox(field);
    } catch {
      this.revertCheckboxState(checkbox, !checked);
    }
  }

  toggleAll(event) {
    const { target: headerCheckbox } = event;
    const { field } = headerCheckbox.dataset;
    const checked = headerCheckbox.checked;
    const checkboxes = this.getFieldCheckboxes(field);

    const idsToUpdate = this.collectIdsToUpdate(checkboxes, checked);
    if (!idsToUpdate.length) return;

    this.patchRequest({ ids: idsToUpdate, field, value: checked })
      .then((response) => {
        if (!response.ok) this.revertCheckboxes(idsToUpdate, field, headerCheckbox, !checked);
      })
      .catch(() => {
        this.revertCheckboxes(idsToUpdate, field, headerCheckbox, !checked);
      });
  }

  updateHeaderCheckbox(field) {
    const checkboxes = this.getFieldCheckboxes(field);
    const headerCheckbox = this.getHeaderCheckbox(field);

    if (headerCheckbox) {
      headerCheckbox.checked = Array.from(checkboxes).every((cb) => cb.checked);
    }
  }

  updateAllHeaderCheckboxes() {
    document.querySelectorAll('input[data-header="true"]').forEach(({ dataset: { field } }) => {
      this.updateHeaderCheckbox(field);
    });
  }

  collectIdsToUpdate(checkboxes, checked) {
    return Array.from(checkboxes)
      .filter((cb) => cb.checked !== checked)
      .map((cb) => {
        cb.checked = checked;
        return cb.dataset.id;
      });
  }

  revertCheckboxState(checkbox, state) {
    checkbox.checked = state;
  }

  revertCheckboxes(ids, field, headerCheckbox, state) {
    headerCheckbox.checked = state;
    ids.forEach((id) => {
      const checkbox = document.querySelector(`input[data-id='${id}'][data-field='${field}']`);
      if (checkbox) this.revertCheckboxState(checkbox, !state);
    });
  }

  async patchRequest(data) {
    const csrfToken = document.querySelector('[name="csrf-token"]').content;
    return fetch('/producers/toggle_campaign_field', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify(data),
    });
  }

  getFieldCheckboxes(field) {
    return document.querySelectorAll(`input[data-field='${field}']:not([data-header='true'])`);
  }

  getHeaderCheckbox(field) {
    return document.querySelector(`input[data-field='${field}'][data-header='true']`);
  }
}